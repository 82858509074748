<template>
    <base-nav container-classes="container-fluid" :show="true"
              class="custom-navbar">

        <template slot="brand">
            <div class="navbar-wrapper">
                <router-link to="/" class="mr-0 mr-md-2">
                    <img src="../../assets/images/suvidi-logo.png" class="logo" alt="Federal Bank"/>
                </router-link>
            </div>
        </template>

        <b-navbar-nav class="mr-auto">
            <b-nav-item to="/admin/home/">
                <span class="nav-link-inner--text text-sm">
                    <i class="ni ni-shop"></i>
                    Dashboard
                </span>
            </b-nav-item>
            <b-nav-item to="/admin/master-data/">
                <a class="nav-link-inner--text text-sm">
                    <i class="ni ni-bullet-list-67"></i>
                    Master Data
                </a>
            </b-nav-item>
            <b-nav-item to="/admin/orders/">
                <a class="nav-link-inner--text text-sm">
                    <i class="ni ni-cart"></i>
                    Orders
                </a>
            </b-nav-item>
            <b-nav-item to="/admin/category/">
                <a class="nav-link-inner--text text-sm">
                    <img src="../../assets/images/menu.png" class="img-fluid" alt="#">
                    Category
                </a>
            </b-nav-item>
            <b-nav-item to="/admin/product/">
                <span class="nav-link-inner--text text-sm">
                    <i class="ni ni-mobile-button"></i>
                    Products
                </span>
            </b-nav-item>
            <b-nav-item to="/admin/transaction/">
                <span class="nav-link-inner--text text-sm">
                    <i class="ni ni-money-coins"></i>
                    Transactions
                </span>
            </b-nav-item>
            <b-nav-item to="/admin/users/">
                <span class="nav-link-inner--text text-sm">
                    <img src="../../assets/images/users.png" class="img-fluid" alt="#">
                    Users
                </span>
            </b-nav-item>
        </b-navbar-nav>

        <UserProfile></UserProfile>
    </base-nav>
</template>
<script>
import { BaseNav } from 'argon/src/components';
import UserProfile from './navbar/UserProfile';

export default {
    components: {
        // CollapseTransition,
        BaseNav,
        // Apps,
        // Notifications,
        UserProfile
        // Modal
    },
    props: {
        type: {
            type: String,
            default: 'default', // default|light
            description: 'Look of the dashboard navbar. Default (Green) or light (gray)'
        }
    },
    computed: {
        routeName () {
            const { name } = this.$route;
            return this.capitalizeFirstLetter(name);
        }
    },
    data () {
        return {
            activeNotifications: false,
            showMenu: false,
            searchModalVisible: false,
            searchQuery: ''
        };
    },
    methods: {
        capitalizeFirstLetter (string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        },
        toggleNotificationDropDown () {
            this.activeNotifications = !this.activeNotifications;
        },
        closeDropDown () {
            this.activeNotifications = false;
        },
        toggleSidebar () {
            this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
        },
        hideSidebar () {
            this.$sidebar.displaySidebar(false);
        }
    }
};
</script>

<style lang="scss">

.custom-navbar {
    border-bottom: 3px solid #ffffff !important;
    background: #bda27f;

    .nav-link {
        color: white !important;
    }

    img.logo {
        max-height: 100px;
        max-width: 70px;
    }

}

</style>
